// #region Imports

import {
    AcknowledgementService,
    ContractCalculationCrudService,
    IContractDocumentDto,
    IContractManagementOverviewDto,
    IInstalmentCalculationDataDto,
    InstalmentCalculationService,
    ISlbCalculationDto,
    SlbService,
} from '@abcfinlab/api/contract';
import {
    ContractsDeprecatedService,
    CreditCheckService, IContractManagementDetailsDto, ICreditCheckDto, ISaleAndLeaseBackCodeDto,
    QuoteService,
} from '@abcfinlab/api/global';
import { EventHub, subscribeOnce, TranslationFacade } from '@abcfinlab/core';
import { BusyBoxService } from '@abcfinlab/ui';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UploadDocumentType } from 'apps/shell/src/app/models/enums/UploadDocumentType.enum';
import {
    ICalculationInfo,
    IInsuranceValues,
} from 'libs/presentation/src/Components/object-value-changed/object-value-changed.component';
import { BehaviorSubject, combineLatest, Observable, of, Subscription } from 'rxjs';
import { catchError, filter, take } from 'rxjs/operators';
import { IContractOverviewDetailsInfo } from '../Models/IContractOverviewDetailsInfo';
import { KnownEvents } from '../Models/KnownEvents';

// #endregion

/**
 * @public
 */
@UntilDestroy()
@Component({
    selector: 'l7-contract-overview-details-view',
    templateUrl: './ContractOverviewDetailsView.html',
    styleUrls: ['./ContractOverviewDetailsView.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContractOverviewDetailsView implements OnInit, OnDestroy {

    // #region Fields

    private readonly _contractCalculationService: ContractCalculationCrudService;
    private readonly _contractsDeprecatedService: ContractsDeprecatedService;
    private readonly _instalmentCalculationService: InstalmentCalculationService;
    private readonly _creditCheckService: CreditCheckService;
    private readonly _eventHub: EventHub;
    private readonly _busyBoxService: BusyBoxService;
    private readonly _translationFacade: TranslationFacade;
    private readonly _acknowledgementService: AcknowledgementService;
    private readonly _quoteService: QuoteService;
    private readonly _slbService: SlbService;
    private readonly _dataSubject: BehaviorSubject<IContractOverviewDetailsInfo | null>;
    private readonly _hasPurchasePriceChanged = new BehaviorSubject<boolean>(false);
    private readonly _isPurchasePriceChangedInactive = new BehaviorSubject<boolean>(false);
    private readonly _slbCalculation = new BehaviorSubject<ISlbCalculationDto>(null);
    private readonly _calculationValues = new BehaviorSubject<ICalculationInfo>(null);
    private readonly _insuranceValues = new BehaviorSubject<IInsuranceValues>(null);
    private readonly _slbCode = new BehaviorSubject<ISaleAndLeaseBackCodeDto>(null);
    private readonly _hasTransparencyRegister: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    private _rowChangedSubscription: Subscription;

    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `ContractOverviewDetailsView` class.
     *
     * @public
     */
    public constructor(contractCalculationService: ContractCalculationCrudService,
        contractsDeprecatedService: ContractsDeprecatedService,
        eventHub: EventHub, busyBoxService: BusyBoxService, translationFacade: TranslationFacade,
        acknowledgementService: AcknowledgementService, quoteService: QuoteService,
        slbService: SlbService, creditCheckService: CreditCheckService,
        instalmentCalculationService: InstalmentCalculationService) {
        this._contractCalculationService = contractCalculationService;
        this._contractsDeprecatedService = contractsDeprecatedService;
        this._instalmentCalculationService = instalmentCalculationService;
        this._creditCheckService = creditCheckService;
        this._eventHub = eventHub;
        this._busyBoxService = busyBoxService;
        this._translationFacade = translationFacade;
        this._acknowledgementService = acknowledgementService;
        this._quoteService = quoteService;
        this._slbService = slbService;

        this._dataSubject = new BehaviorSubject(null);
        this._rowChangedSubscription = Subscription.EMPTY;
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `data` property.
     *
     * @public
     * @readonly
     */
    public get data(): Observable<IContractOverviewDetailsInfo | null> {
        return this._dataSubject.asObservable();
    }

    /**
     * Returns the `data` property.
     *
     * @public
     * @readonly
     */
    public get hasPurchasePriceChanged(): Observable<boolean | null> {
        return this._hasPurchasePriceChanged.asObservable();
    }

    /**
     * Returns the `isPurchasePriceChangedInactive` property.
     *
     * @public
     * @readonly
     */
    public get isPurchasePriceChangedInactive(): Observable<boolean | null> {
        return this._isPurchasePriceChangedInactive.asObservable();
    }

    /**
     * Returns the `slbCalculation` property.
     *
     * @public
     * @readonly
     */
    public get slbCalculation(): Observable<ISlbCalculationDto | null> {
        return this._slbCalculation.asObservable();
    }

    /**
     * Returns the `slbCode` property.
     *
     * @public
     * @readonly
     */
    public get hasTransparencyRegister(): Observable<boolean> {
        return this._hasTransparencyRegister.asObservable();
    }

    /**
     * Returns the `calculationValues` property.
     *
     * @public
     * @readonly
     */
    public get calculationValues(): Observable<ICalculationInfo> {
        return this._calculationValues.asObservable();
    }

    /**
     * Returns the `insuranceValues` property.
     *
     * @public
     * @readonly
     */
    public get insuranceValues(): Observable<IInsuranceValues> {
        return this._insuranceValues.asObservable();
    }

    // #endregion

    // #region Methods

    public ngOnInit(): void {
        this._rowChangedSubscription = this._eventHub.getEvent<IContractManagementOverviewDto>(KnownEvents.CONTRACT_OVERVIEW_ROW_CHANGED).subscribe((row) => {
            this._slbCode.next(row.slb_code);
            const getChangedCalculation$ = this._instalmentCalculationService.getInstalmentCalculation({ contractId: row.contract_number }).pipe(take(1), catchError(() => of(null)));
            const getSlb$ = row.slb ? this._slbService.getSlb({ contractId: row.contract_number }).pipe(take(1), catchError(() => of(null))) : of(null);
            const getAcknowledgement$ = row.slb ? of(null) : this._acknowledgementService.readByContractNumber({ contractNumber: row.contract_number }).pipe(take(1), catchError(() => of(null)));
            subscribeOnce(this._busyBoxService.show('', this._translationFacade.translate('global.busy'), combineLatest([
                this._contractsDeprecatedService.getContractDetails({ contractNumber: row.contract_number }).pipe(take(1), catchError(() => of(null))),
                getAcknowledgement$,
                this._quoteService.getQuoteByIdV({ quoteId: row.quote_id }).pipe(take(1), catchError(() => of(null))),
                this._contractCalculationService.getExpenses({ contractId: row.contract_number }).pipe(take(1), catchError(() => of(null))),
                this._quoteService.getQuoteCalculation({ leasingQuoteId: row.quote_id }).pipe(take(1), catchError(() => of(null))),
                this._quoteService.getRefinancingInterest({ quoteId: row.quote_id }).pipe(take(1), catchError(() => of(null))),
                this._creditCheckService.getCreditCheck({ quoteId: row.quote_id }).pipe(take(1), catchError(() => of(null))),
                getSlb$,
                getChangedCalculation$,
                this._quoteService.getQuoteById({ quoteId: row.quote_id }).pipe(take(1), catchError(() => of(null))),
            ])), ([contractDetails, acknowledgement, quote, expenses, calculation, refinancingInterest, creditCheck, slb, changedCalculation, oldGetQuote]) => {
                if (row.slb) {
                    const hasPurchasePriceChanged = (slb.activeSlbResponse && contractDetails.object.value !== slb?.activeSlbResponse?.purchasePrice)
                        || (slb.inactiveSlbResponse && contractDetails.object.value !== slb?.inactiveSlbResponse?.purchasePrice);
                    this._hasPurchasePriceChanged.next(hasPurchasePriceChanged);
                    this._isPurchasePriceChangedInactive.next(slb.inactiveSlbResponse && contractDetails.object.value !== slb.inactiveSlbResponse?.purchasePrice);
                    if (this._hasPurchasePriceChanged.getValue() && this._isPurchasePriceChangedInactive.getValue()) {
                        this._slbCalculation.next(slb.inactiveSlbResponse?.calculation);
                        this._calculationValues.next({
                            totalLeasingValueNet: slb.inactiveSlbResponse?.calculation?.totalLeasingValueNet,
                            lastInstalment: slb.inactiveSlbResponse?.calculation?.lastInstalment,
                            firstInstalment: slb.inactiveSlbResponse?.calculation?.firstInstalment,
                            instalment: slb.inactiveSlbResponse?.calculation?.instalment,
                            downPayment: slb.inactiveSlbResponse?.calculation?.downPayment,
                            residualValue: slb.inactiveSlbResponse?.calculation?.residualValue,
                        });
                    } else if (this._hasPurchasePriceChanged.getValue() && !this._isPurchasePriceChangedInactive.getValue()) {
                        this._slbCalculation.next(slb.activeSlbResponse?.calculation);
                        this._calculationValues.next({
                            totalLeasingValueNet: slb.activeSlbResponse?.calculation?.totalLeasingValueNet,
                            lastInstalment: slb.activeSlbResponse?.calculation?.lastInstalment,
                            firstInstalment: slb.activeSlbResponse?.calculation?.firstInstalment,
                            instalment: slb.activeSlbResponse?.calculation?.instalment,
                            downPayment: slb.activeSlbResponse?.calculation?.downPayment,
                            residualValue: slb.activeSlbResponse?.calculation?.residualValue,
                        });
                    }
                } else {
                    this._hasPurchasePriceChanged.next(acknowledgement?.purchase_price && contractDetails.object.value !== acknowledgement?.purchase_price);
                    this._calculationValues.next({
                        totalLeasingValueNet: acknowledgement?.purchase_price,
                        lastInstalment: acknowledgement?.last_instalment,
                        firstInstalment: acknowledgement?.first_instalment,
                        instalment: acknowledgement?.instalment,
                        downPayment: acknowledgement?.down_payment,
                        residualValue: acknowledgement?.residual_value,
                    });
                }
                this._insuranceValues.next({ insuranceValue: expenses?.insurance_value, insuranceType: expenses?.insurance_type });
                this._hasTransparencyRegister.next(oldGetQuote.transparency_register_entry);
                this._dataSubject.next({
                    row: row,
                    details: contractDetails,
                    acknowledgement: acknowledgement,
                    quote: quote,
                    insuranceAndHandlingFee: expenses,
                    calculation: calculation,
                    refinancingInterest,
                    creditCheck,
                    slb,
                    changedCalculation,
                });
                this._eventHub.publish<IContractManagementDetailsDto>(KnownEvents.CONTRACT_QUOTE_DETAILS, contractDetails);
                this._eventHub.publish<ICreditCheckDto>(KnownEvents.CREDIT_CHECK, creditCheck);
            });
        });
        this._eventHub.getEvent<IInstalmentCalculationDataDto>(KnownEvents.INSTALMENT_CHANGED_IN_RECALCULATION)
            .pipe(untilDestroyed(this))
            .subscribe((x) => {
                const currentData = this._dataSubject.value;
                const changedData = {
                    ...currentData,
                    ...{
                        changedCalculation: {
                            ...currentData.changedCalculation,
                            ...{
                                instalment: x.instalment,
                                yearlyInterest: x.yearlyInterest,
                            },
                        },
                    },
                };

                this._dataSubject.next(changedData);
            });
        this._eventHub.getEvent<IContractDocumentDto>(KnownEvents.DOCUMENT_DELETED)
            .pipe(
                filter(x => x.type === UploadDocumentType.ACKNOWLEDGEMENT_SIGNED || x.type === UploadDocumentType.ACKNOWLEDGEMENT_SIGNED_PAPER),
                untilDestroyed(this),
            )
            .subscribe(() => {
                const currentData = this._dataSubject.value;
                const changedData = {
                    ...currentData,
                    ...{
                        changedCalculation: null,
                    },
                };

                this._dataSubject.next(changedData);
            });
    }

    public ngOnDestroy(): void {
        this._rowChangedSubscription.unsubscribe();
    }

    // #endregion

}
