import { IContractManagementDetailsDto } from '@abcfinlab/api/global';
import { IPurchaseEntryDto } from '@abcfinlab/api/contract';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ObjectValueValidator } from '../../../Validators/object-value.validator';

@Component({
    selector: 'l7-purchase-entry-object-value',
    templateUrl: './purchase-entry-object-value.component.html',
    styleUrls: ['./purchase-entry-object-value.component.scss'],
})
export class PurchaseEntryObjectValueComponent implements OnInit {

    form: UntypedFormGroup;
    constructor(
        private readonly _fb: UntypedFormBuilder,
        private readonly objectValueValidator: ObjectValueValidator,
        public dialogRef: MatDialogRef<PurchaseEntryObjectValueComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { quote: IContractManagementDetailsDto; purchase_entry: IPurchaseEntryDto },
    ) {
        this.form = this._fb.group({
            original_value: [{ value: null, disabled: true }, [Validators.required]],
            object_value: [{ value: null, disabled: false }, [Validators.required, this.objectValueValidator.mustBeLowerThen()]],
        },
        );
    }

    ngOnInit() {
        this.form.get('original_value').patchValue(this.data.quote.quote_calculation.quote_calculation.total_leasing_value);
        this.form.get('object_value').patchValue(this.data.purchase_entry.object_value);
    }

    onClose(result: boolean) {
        this.dialogRef.close({
            cancel: result,
        });
    }

    onSave(result: boolean) {
        this.dialogRef.close({
            cancel: result,
            object_value: this.form.getRawValue().object_value,
        });
    }

    onChange(event, form: UntypedFormGroup) {
        const controlName = event.target.attributes.formcontrolname.value;
        if (!form.get(controlName).touched) {
            form.get(controlName).markAsTouched();
        }
    }

}
