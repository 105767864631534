// #region Imports

import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

interface IContractOverviewTakeoverConfirmationUploadDialogViewData {
    acknowledgementDate: string;
}

// #endregion

/**
 * @public
 */
@Component({
    selector: 'l7-contract-overview-takeover-confirmation-upload-dialog-view',
    templateUrl: './ContractOverviewTakeoverConfirmationUploadDialogView.html',
    styleUrls: ['./ContractOverviewTakeoverConfirmationUploadDialogView.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContractOverviewTakeoverConfirmationUploadDialogView {

    // #region Fields

    private readonly _dialogRef: MatDialogRef<any>;

    private _files: Array<File> | null;
    private readonly _data: IContractOverviewTakeoverConfirmationUploadDialogViewData;

    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `ContractOverviewTakeoverConfirmationUploadDialogView` class.
     *
     * @public
     */
    public constructor(dialogRef: MatDialogRef<ContractOverviewTakeoverConfirmationUploadDialogView>, @Inject(MAT_DIALOG_DATA) data: IContractOverviewTakeoverConfirmationUploadDialogViewData) {
        this._dialogRef = dialogRef;
        this._data = data;
        this._files = null;
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `acknowledgementDate` property.
     *
     * @public
     * @readonly
     */
    public get acknowledgementDate(): string {
        return this._data.acknowledgementDate;
    }

    // #endregion

    // #region Methods

    public onFilesChanged(value: Array<File>): void {
        this._files = value;
    }

    /**
     * @internal
     */
    public onClose(canceled: boolean): void {
        this._dialogRef.close({
            files: this._files,
            canceled: canceled,
        });
    }

    // #endregion

}
