// #region Imports

import { TranslationModule } from '@abcfinlab/core';
import { LayoutModule } from '@abcfinlab/layout';
import {
    BannerModule,
    BreakpointObserverModule,
    CheckboxGroupModule,
    DialogHeaderModule,
    MemberValuePipeModule,
    MotionModule,
    Motions,
    PhoneControlValueAccessorModule,
    SpacerModule,
    TextBlockModule,
} from '@abcfinlab/ui';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MAT_PAGINATOR_DEFAULT_OPTIONS, MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { NgxCurrencyDirective } from 'ngx-currency';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { CreateRetailerUserView } from '../Views/Dialogs/CreateRetailerUserView';
import { CreateRetailerView } from '../Views/Dialogs/CreateRetailerView';
import { EditRetailerUserView } from '../Views/EditRetailerUserView';
import { EditRetailerView } from '../Views/EditRetailerView';
import { RetailerConfigurationOverviewView } from '../Views/RetailerConfigurationOverviewView';
import { RetailersOverviewView } from '../Views/RetailersOverviewView';
import { AdministrationNavigationModule } from './AdministrationNavigationModule';
import { PresentationModule } from '@abcfinlab/presentation';

// #endregion

/**
 * The `AdministrationModule` module.
 *
 * @public
 */
@NgModule({
    imports: [
        AdministrationNavigationModule,
        CommonModule,
        PresentationModule,
        LayoutModule,
        FlexLayoutModule,
        TranslationModule,
        MatInputModule,
        MatFormFieldModule,
        MatButtonModule,
        MatCardModule,
        MatIconModule,
        MatStepperModule,
        MatDialogModule,
        MatRippleModule,
        CdkStepperModule,
        MatTableModule,
        MatPaginatorModule,
        TextBlockModule,
        MemberValuePipeModule,
        MatCheckboxModule,
        MatDividerModule,
        MatChipsModule,
        MatAutocompleteModule,
        MatRadioModule,
        MatSlideToggleModule,
        ReactiveFormsModule,
        MatSelectModule,
        MatTabsModule,
        CheckboxGroupModule,
        MatProgressSpinnerModule,
        BreakpointObserverModule,
        NgxCurrencyDirective,
        DialogHeaderModule,
        SpacerModule,
        BannerModule,
        NgxMaskDirective,
        NgxMaskPipe,
        MotionModule.forRoot({
            motions: [
                Motions.fadeSlideIn,
            ],
        }),
        PhoneControlValueAccessorModule,
    ],
    declarations: [
        RetailersOverviewView,
        CreateRetailerView,
        CreateRetailerUserView,
        EditRetailerView,
        EditRetailerUserView,
        RetailerConfigurationOverviewView,
    ],
    providers: [provideNgxMask(), { provide: MAT_PAGINATOR_DEFAULT_OPTIONS, useValue: { formFieldAppearance: 'fill' } }],
})
export class AdministrationModule { }
