// #region Imports

import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { CreateRetailerViewPresenter } from './CreateRetailerViewPresenter';

// #endregion

/**
 * The `createRetailerView` view.
 *
 * @public
 */
@Component({
    selector: 'l7-create-retailer-view',
    templateUrl: './CreateRetailerView.html',
    styleUrls: ['./CreateRetailerView.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        CreateRetailerViewPresenter,
        {
            provide: STEPPER_GLOBAL_OPTIONS,
            useValue: { showError: true, displayDefaultIndicatorType: false },
        },
    ],
})
export class CreateRetailerView implements OnInit, OnDestroy {

    // #region Fields

    private readonly _presenter: CreateRetailerViewPresenter;

    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `CreateRetailerView` class.
     *
     * @public
     */
    public constructor(presenter: CreateRetailerViewPresenter) {
        this._presenter = presenter;
    }

    // #endregion

    // #region Proeprties

    /**
     * Returns the presenter of the `CreateRetailerView`
     *
     * @public
     * @readonly
     */
    public get presenter(): CreateRetailerViewPresenter {
        return this._presenter;
    }

    // #endregion

    // #region Methods

    /**
     * @internal
     */
    public ngOnInit(): void {
        this._presenter.initialize();
    }

    /**
     * @internal
     */
    public ngOnDestroy(): void {
        this._presenter.dispose();
    }

    // #endregion

}
