// #region Imports

import { TranslationFacade } from '@abcfinlab/core';
import { ToastService } from '@abcfinlab/ui';
import {
    ChangeDetectionStrategy,
    Component, Input,
    OnDestroy,
} from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { IContractManagementOverviewInfo } from 'libs/contractManagement/src/Models/IContractManagementOverviewInfo';
import {
    ContractOverviewKuevViewPresenter,
} from './ContractOverviewKuevViewPresenter';

// #endregion

/**
 * @public
 */
@UntilDestroy()
@Component({
    selector: 'l7-contract-overview-kuev-view',
    templateUrl: './ContractOverviewKuevView.html',
    styleUrls: ['./ContractOverviewKuevView.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [ContractOverviewKuevViewPresenter],
})
export class ContractOverviewKuevView implements OnDestroy {

    // #region Fields
    private _overview: IContractManagementOverviewInfo | null;
    private _ready: boolean;
    private readonly _presenter: ContractOverviewKuevViewPresenter;
    private readonly _toastService: ToastService;
    private readonly _translationFacade: TranslationFacade;
    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `ContractOverviewKuevView` class.
     *
     * @public
     */
    public constructor(presenter: ContractOverviewKuevViewPresenter, toastService: ToastService, translationFacade: TranslationFacade) {
        this._presenter = presenter;
        this._toastService = toastService;
        this._translationFacade = translationFacade;
    }

    // #endregion

    // #region Properties
    /**
     * Gets or sets the `overview` property.
     *
     * @public
     * @readonly
     */
    public maxColumns: number = 4;
    public minColumns: number = 4;

    @Input()
    public get overview(): IContractManagementOverviewInfo {
        return this._overview;
    }

    public set overview(value: IContractManagementOverviewInfo) {
        this._overview = value;
    }

    /**
     * Gets or sets the `ready` property.
     *
     * @public
     */
    @Input()
    public get ready(): boolean {
        return this._ready;
    }

    public set ready(value: boolean) {
        this._ready = value;
    }

    /**
     * Returns the presenter of the `ContractOverviewKuevView`
     *
     * @public
     * @readonly
     */
    public get presenter(): ContractOverviewKuevViewPresenter {
        return this._presenter;
    }

    // #endregion

    // #region Methods

    /**
     * @internal
     */
    public onLinkCopied(): void {
        this._toastService.show(this._translationFacade.translate('contract_management.takeover_confirmation.signing_link.copied'), 'success');
    }

    /**
     * @internal
     */
    public ngOnDestroy(): void {
        this._presenter.dispose();
    }

    // #endregion

}
