// #region Imports

import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DigitalSignDialogViewPresenter } from './DigitalSignDialogViewPresenter';
import { MatStepper } from '@angular/material/stepper';
import { BehaviorSubject } from 'rxjs';
import { ToastService } from '@abcfinlab/ui';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { ISerialNumberRequestDto } from '@abcfinlab/api/contract';

// #endregion

/**
 * The `DigitalSignDialogView` view.
 *
 * @public
 */
@Component({
    selector: 'l7-digital-sign-view',
    templateUrl: './DigitalSignDialogView.html',
    styleUrls: ['./DigitalSignDialogView.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        DigitalSignDialogViewPresenter,
        {
            provide: STEPPER_GLOBAL_OPTIONS,
            useValue: { showError: true, displayDefaultIndicatorType: false },
        },
    ],
})
export class DigitalSignDialogView implements OnInit, OnDestroy {

    // #region Fields

    private readonly _presenter: DigitalSignDialogViewPresenter;
    private readonly _toastService: ToastService;
    public isOnLastStep: boolean = false;
    public isSignatureDirty$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public resetSignature$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    public signature$: BehaviorSubject<Blob> = new BehaviorSubject<Blob>(null);
    public serialNumberStepTouched: boolean = false;
    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `DigitalSignDialogView` class.
     *
     * @public
     */
    public constructor(presenter: DigitalSignDialogViewPresenter, toastService: ToastService) {
        this._presenter = presenter;
        this._toastService = toastService;
    }

    // #endregion

    // #region Properties
    @ViewChild('stepper') public stepperCreateQuote: MatStepper;
    /**
     * Returns the presenter of the `DigitalSignDialogView`
     *
     * @public
     * @readonly
     */
    public get presenter(): DigitalSignDialogViewPresenter {
        return this._presenter;
    }

    // #endregion

    // #region Methods

    /**
     * @internal
     */
    public ngOnInit(): void {
        this._presenter.initialize();
    }

    /**
     * @internal
     */
    public ngOnDestroy(): void {
        this._presenter.dispose();
    }

    public nextStep(): void {
        this.stepperCreateQuote.next();
    }

    public setLastStep(isLast: boolean): void {
        if ((this.presenter.form.controls.general.controls.purchasePrice.valid || this.presenter.form.controls.general.controls.purchasePrice.disabled)
            && this.presenter.form.controls.general.controls.acknowledgementDate.valid
            && ((this.presenter.form.controls.files.controls.serialNumber.value && this.presenter.form.controls.choices.controls.isSerialNumberAuto.value)
                || (this.presenter.form.controls.files.controls.serialNumbers.valid && !this.presenter.form.controls.choices.controls.isSerialNumberAuto.value))) {
            this.isOnLastStep = isLast;
            this.presenter.form.controls.finalLocal.controls.signedByDate.markAsTouched();
        } else {
            this._toastService.show('Bitte überprüfen Sie ihre Angaben.', 'danger');
        }
    }

    public formatSerialNumber(sn: ISerialNumberRequestDto): string {
        const targetFormat = `${sn.position}-${sn.objectDescription}-${sn.yearOfManufacture}-${sn.serialNumber}`;
        return sn.serialNumber;
    }

    public previousStep(): void {
        this.stepperCreateQuote.previous();
    }

    public doAcknowledgmentLocal(): void {
        if (this.signature$.getValue()
            && this.presenter.form.controls.finalLocal.valid
            && this.presenter.form.controls.general.controls.localSigningCheckBox2.value
            && this.presenter.form.controls.general.controls.localSigningCheckBox3.value
            && !(this.presenter.isPriceChanged && !this.presenter.form.controls.general.controls.localSigningCheckBox1.value)) {
            this.presenter.doAcknowledgmentLocal(this.signature$.getValue());
        } else {
            this.presenter.form.controls.finalLocal.controls.signedByFirstName.markAsTouched();
            this.presenter.form.controls.finalLocal.controls.signedByLastName.markAsTouched();
            this._toastService.show('Bitte überprüfen Sie ihre Angaben.', 'danger');
        }
    }

    public doAcknowledgmentRemote(): void {
        if (!this.presenter.form.controls.finalRemote.controls.signerEmail.hasError('email') && !this.presenter.form.controls.finalRemote.controls.signerEmail.hasError('required')) {
            this.presenter.doAcknowledgmentRemote();
        } else {
            this._toastService.show('Bitte überprüfen Sie ihre Angaben.', 'danger');
        }
    }
    // #endregion

}
