import { ContractsDocumentService } from '@abcfinlab/api/contract';
import { TranslationFacade } from '@abcfinlab/core';
import { ToastService } from '@abcfinlab/ui';
import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'l7-document-confirmation-form',
    templateUrl: './document-confirmation-form.component.html',
    styleUrls: ['./document-confirmation-form.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentConfirmationFormComponent implements AfterViewInit {

    private readonly _contractsDocumentService: ContractsDocumentService;
    private readonly _ToastService: ToastService;
    private readonly _translationFacade: TranslationFacade;
    @Input() isConfirmed: boolean;
    @Input() isReadonlyUser: boolean;
    @Input() documentId: string;
    @Output() closeDialog: EventEmitter<boolean> = new EventEmitter<boolean>();
    public confirmationForm: UntypedFormGroup;
    constructor(formBuilder: UntypedFormBuilder, contractsDocumentService: ContractsDocumentService, ToastService: ToastService, translationFacade: TranslationFacade) {
        this._contractsDocumentService = contractsDocumentService;
        this._ToastService = ToastService;
        this._translationFacade = translationFacade;
        this.confirmationForm = formBuilder.group({
            proofOfPaymentConfirmation: [{ value: false }, [Validators.required]],
        });
    }

    ngAfterViewInit() {
        this.confirmationForm.controls.proofOfPaymentConfirmation.setValue(this.isConfirmed);
    }

    public saveForm(): void {
        const subscription = this._contractsDocumentService.updateProofOfPayment({
            documentId: this.documentId,
            body: { confirmed: this.confirmationForm.value.proofOfPaymentConfirmation },
        })
            .subscribe(() => {
                this._ToastService.show(this._translationFacade.translate('global.save_successful'), 'success');
                this.closeDialog.emit(true);
            },
            (_error) => {
                this._ToastService.show(this._translationFacade.translate('error.generic_error'), 'danger');
            },
            () => {
                subscription.unsubscribe();
            });
    }

}
