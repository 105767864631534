import { UntypedFormControl, ValidatorFn } from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ObjectValueValidator {

    public mustBeLowerThen(): ValidatorFn {
        return (formControl: UntypedFormControl) => {
            if (!formControl.parent) {
                return null;
            }

            const originalValueControl = formControl?.parent.get('original_value');
            const newValueControl = formControl;

            if (!originalValueControl || !originalValueControl) {
                return null;
            }

            const originalValue = originalValueControl.value;
            const newValue = newValueControl.value;

            if (!newValue) {
                return null;
            }

            if (newValue < originalValue) {
                return null;
            } else {
                return { isNotLower: true };
            }
        };
    }

}
