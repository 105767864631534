// #region Imports

import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { UploadDeliveryDocumentDialogViewPresenter } from './UploadDeliveryDocumentDialogViewPresenter';

// #endregion

/**
 * The `UploadDeliveryDocumentDialogView` view.
 *
 * @public
 */
@Component({
    selector: 'l7-upload-delivery-document-dialog-view',
    templateUrl: './UploadDeliveryDocumentDialogView.html',
    styleUrls: ['./UploadDeliveryDocumentDialogView.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        UploadDeliveryDocumentDialogViewPresenter,
        {
            provide: STEPPER_GLOBAL_OPTIONS,
            useValue: { showError: true, displayDefaultIndicatorType: false },
        },
    ],
})
export class UploadDeliveryDocumentDialogView implements OnInit, OnDestroy {

    // #region Fields

    private readonly _presenter: UploadDeliveryDocumentDialogViewPresenter;

    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `UploadDeliveryDocumentDialogView` class.
     *
     * @public
     */
    public constructor(presenter: UploadDeliveryDocumentDialogViewPresenter) {
        this._presenter = presenter;
    }

    // #endregion

    // #region Proeprties

    /**
     * Returns the presenter of the `UploadDeliveryDocumentDialogView`
     *
     * @public
     * @readonly
     */
    public get presenter(): UploadDeliveryDocumentDialogViewPresenter {
        return this._presenter;
    }

    // #endregion

    // #region Methods

    /**
     * @internal
     */
    public ngOnInit(): void {
        this._presenter.initialize();
    }

    /**
     * @internal
     */
    public ngOnDestroy(): void {
        this._presenter.dispose();
    }

    // #endregion

}
