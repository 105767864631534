// #region Imports

import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { FeedbackWidgetViewPresenter } from './FeedbackWidgetViewPresenter';

// #endregion

/**
 * The `FeedbackWidgetView` view.
 *
 * @public
 */
@Component({
    selector: 'l7-feedback-widget-view',
    templateUrl: './FeedbackWidgetView.html',
    styleUrls: ['./FeedbackWidgetView.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        FeedbackWidgetViewPresenter,
    ],
})
export class FeedbackWidgetView implements OnInit, OnDestroy {

    // #region Fields

    private readonly _presenter: FeedbackWidgetViewPresenter;

    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `FeedbackWidgetView` class.
     *
     * @public
     */
    public constructor(presenter: FeedbackWidgetViewPresenter) {
        this._presenter = presenter;
    }

    // #endregion

    // #region Proeprties

    /**
     * Returns the presenter of the `FeedbackWidgetView`
     *
     * @public
     * @readonly
     */
    public get presenter(): FeedbackWidgetViewPresenter {
        return this._presenter;
    }

    // #endregion

    // #region Methods

    /**
     * @internal
     */
    public ngOnInit(): void {
        this._presenter.initialize();
    }

    /**
     * @internal
     */
    public ngOnDestroy(): void {
        this._presenter.dispose();
    }

    // #endregion

}
